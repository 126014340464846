/*
 * 命名规范
 * 全小写 以 ` _ ` 作为分割
 */
export default {
    // 全局公共翻译
    home: '首页',
    login: '登录',
    search: '搜索',
    tip: '提示',
    lang: '语言',
    create_time: '创建时间',
    update_time: '修改时间',
    action: '操作',
    empty: '暂无数据',
    all: '全部',
    content: '内容',
    url: '链接',
    icon: '图标',
    image: '图片',
    type: '类型',
    category: '分类',
    name: '名称',
    // Global End

    // 按钮部分
    button: {
        cancel: '取消',
        ok: '确定',
        del: '删除',
        save: '保存',
        add: '添加',
        edit: '编辑',
        refresh: '刷新',
        select_all: '全选',
        // Category
        add_category: '添加分类',
        // Images
        upload_image: '上传图片',
        new_folder: '新建文件夹',
        edit_folder: '编辑文件夹',

        go_back: '返回',
        page: '页面'
    },
    // Button End

    // 导航菜单
    menu: [
        { path: '/home', title: '首页'},
        { path: '/goldenSun', title: '走进金太阳'},
        { path: '/solution', title: '解决方案'},
        { path: '/productCenter', title: '产品中心'},
        { path: '/technicalSupport', title: '技术支持'},
        { path: '/investor', title: '投资者'},
        { path: '/media', title: '媒体'},
        // { path: '/home', title: '关联公司'},
    ],
    // Menu End

    // 表单
    form: {
        // 公共部分
        name:   '名称',
        title:  '标题',
        desc:   '描述',
        status: '状态',
        create_time: '上传时间',
        update_time: '修改时间',
        select_taxonomy: '选择分类',
        order: '排序',

        // 其他（other）：以文件名来命名
        category: {
            add: {
                parent: '父级分类',
                status: '状态'
            }
        },
        images: {
            edit: {
                size: '图片大小',
                path: '图片地址'
            }
        },
        region: {
            name: '请输入地区',
            level: '请选择等级',
            code: '请输入行政代码',
        }
    },
    // From All End

    // 所有(表单)验证提示
    validate: {
        max_length: '限制长度为',
        empty: '此项不能为空',
        empty_radio: '请选择相关选项',
    },
    // (From) All Tip End

    // 提示(成功/警告)
    tips: {
        search: '请输入搜索内容',
        select: '请选择',
        enter: '请输入内容',
        success: '操作成功',
        error: '操作失败',
        del: '删除后将无法恢复，您确定要删除吗？',
        del_folder: '请先转移或删除此文件夹下所有文件（完成请忽略）',
        not_selected: '没有选中项，请您选中相关选项',
        selected: '已选 ',
        total: '共 ',
        item: ' 项',

        // 其他（other）：以文件名来命名
        images: {
            query: '请输入标题或描述',
            folder: '选择文件夹',
            upload_text: '将图片拖到此处，或点击上传',
            upload_tip: '图片只能上传 [ jpg、png ]',
            isLt5K: '大小不能超过 500KB',
            limit: '限制上传为5张图片',
            limit_tip: '上传超出限制，请重新上传'
        },

        error_post: '文章不存在'
    },
    // Tip All (Success/Warning)

    // 页面内容
    page: {
        home: {
            more: '查看更多',
            email: '邮箱',
            address: '地址',
        },
        // console
        console: {
            menu: {
                notice: '公告',
                home: '首页',
                activity: '活动中心',
                add_activity: '添加活动',
                msg: '消息与邮件',
                setting: '设置',
                account: '帐号与密码',
                manage: '管理',
                images: '图片管理',
                category: '分类',
                address: '地址',
                product_type: '产品类型',
                product_app: '应用行业',
                product: '产品',
                product_list: '产品列表',
                solution: '解决方案',
                solution_list: '解决方案列表',
                page_settings: '页面设置',
                home_settings: '首页设置',
                menu_settings: '菜单设置',
                banner_settings: 'Banner设置',
                investor_settings: '投资者设置',
                technicalSupport_settings: '技术支持设置',
                ad_settings: '广告设置',
                golden_sun: '走进金太阳',
                product_center: '产品中心',
                technical_support: '技术支持',
                technical_support_list: '技术支持列表',
                media: '媒体',
                media_list: '媒体列表',
                investor: '投资者关系',
                investor_list: '投资者列表',
                dynamics: '动态',
                activity_images: '活动图集',
                title: '标题',
                desc: '描述',
                list: '列表',
                about_us: '关于我们',
                contact_us: '联系我们',
                customer_service: '客服',
                wxCode: '微信小程序',
                copyright: '备案信息',
                info: '基本信息'
            },
            ad_settings: {
              posts: '文章设置'
            }
        },
        media: {
            video_url: '视频链接',
            images: '图册'
        },
        product: {
            name: '名称',
            model: '型号',
            desc: '说明',
            images: '图片',
            product_type: '产品类型',
            product_app: '应用行业',
            workpiece: '工件/基材',
            subsidiary: '子公司',
            content: '内容',
            wxVideo: '视频号ID'
        },
        goldenSun: {
            company_profile: '公司简介',
            vision_mission: '愿景使命',
            development_history: '发展历程',
            business_culture : '企业文化',
            talent_concept: '人才理念',
            social_responsibility: '社会责任',
            year: '年分'
        },
        technicalSupport: {
            title: '标题',
            name: '姓名',
            position: '职位',
        },

        productCenter: {
            product: '按产品分类',
            apps: '按应用分类',
            asc: '正序',
            desc: '倒序',
            current_taxonomy: '当前选择分类',
            product_nums: '产品数'
        },
        investor: {
            view_pdf: '查看PDF',
            year: '年度',
            create_time: '创建时间'
        },
        productType: {
            category: '分类名',
            desc: '描述',
            order: '排序',
            create_user: '创建用户',
            create_time: '创建时间',
            update_time: '修改时间'
        }
    }
    // Page End
}  