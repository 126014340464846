import config from '@/config'

export const TOKEN_KEY = ''

export const setToken = (token) => {
  sessionStorage.setItem(TOKEN_KEY + 'apiAuth', token)
}

export const getToken = () => {
  const token = sessionStorage.getItem(TOKEN_KEY + 'apiAuth')
  if (token) {
    return token
  } else {
    return false
  }
}

/*
 * 保存用户信息
 */
export const setUserData = (userInfo) => {
  sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
}

/*
 * 获取用户信息
 */
export const getUserData = () => {
  const userInfo = sessionStorage.getItem('userInfo')
  if (userInfo) {
    return JSON.parse(userInfo)
  } else {
    return false
  }
}

/*
 * 获取
 */
export const getData = (key, type = false) => {
  const value = localStorage.getItem(key)
  if (value) {
    if(type) {
      return JSON.parse(value)
    }
    return value
  } else {
    return false
  }
}

/*
 * 获取
 */
export const setData = (key, value) => {
  localStorage.setItem(key, value)
}

/*
 * 存储
 */
export const setSessionData = (key, value) => {
  sessionStorage.setItem(key, value)
}

/*
 * 获取
 */
export const getSessionData = (key, type) => {
  const value = sessionStorage.getItem(key)
  if (value) {
    if(type) {
      return JSON.parse(value)
    }
    return value
  } else {
    return false
  }
}

/*
 * 选择的语言
 */
export const localSave = (key, value) => {
  localStorage.setItem(key, value)
}

export const localRead = (key) => {
  return localStorage.getItem(key) || ''
}

