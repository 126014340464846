import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { localRead } from '@/utils/auth'
import customZhCn from './lang/zh-CN'
import customZhTw from './lang/zh-TW'
import customEnUs from './lang/en-US'
import enUsLocale from 'element-ui/lib/locale/lang/en'
import zhCnLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTwLocale from 'element-ui/lib/locale/lang/zh-TW'
Vue.use(VueI18n)

// 自动根据浏览器系统语言设置语言
const navLang = navigator.language

const localLang = (navLang === 'zh-CN' || navLang === 'zh-TW' || navLang === 'en-US') ? navLang : false
// 优先读取缓存里的选择语种
let lang =  localRead('local') || localLang || 'zh-CN'

// vue-i18n 6.x+写法
Vue.locale = () => {}
const messages = {
  'zh-CN': Object.assign(zhCnLocale, customZhCn),
  'zh-TW': Object.assign(zhTwLocale, customZhTw),
  'en-US': Object.assign(enUsLocale, customEnUs)
}

const i18n = new VueI18n({
    locale: lang,
    messages
})
  
export default i18n