import axios from '@/utils/request';

/**
 * 用户登录
 * @param username
 * @param password
 * @returns {never}
 */
export const login = ({ username, password }) => {
    const data = {
      username,
      password
    }
    return axios.request({
      url: 'admin/login/index',
      data,
      method: 'post'
    })
}

/**
 * 用户登出
 * @returns {never}
 */
export const logout = () => {
    return axios.request({
      url: 'admin/login/logout',
      method: 'get'
    })
}

/**
 * 获取当前用户信息
 * @returns {never}
 */
export const getUserInfo = () => {
    return axios.request({
      url: 'admin/login/getUserInfo',
      method: 'get'
    })
}