import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import i18n from './locale'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)



Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
