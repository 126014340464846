/*
 * 命名规范
 * 全小写 以 ` _ ` 作为分割
 */
export default {
    // 全局公共翻译
    home: '首頁',
    login: '登錄',
    search: '搜索',
    tip: '提示',
    // Global End


    // 按钮部分
    button: {
        cancel: '取消',
        ok: '確定',
        del: '刪除',
        add: '添加',
        edit: '編輯',
        refresh: '刷新',
        select_all: '全選',
        // Category
        add_category: '添加分類',
        // Images
        upload_image: '上傳圖片',
        new_folder: '新建文件夾',
        edit_folder: '編輯文件夾',
    },
    // Button End

    // 导航菜单
    menu:  [
        { path: '/home', title: '首頁'},
        { path: '/job', title: '工作'},
        { path: '/find', title: '接單中心'},
        { path: '/shop', title: '商城'},
        { path: '/sign', title: '登入'},
        { path: '/pricing', title: '價格'},
        { path: '/search', title: '搜索'}
    ],
    // Menu End

    // 表单
    form: {
        // 公共部分
        name:   '名稱',
        title:  '標題',
        desc:   '描述',
        status: '狀態',
        create_time: '上傳時間',
        update_time: '修改時間',

        // 其他（other）：以文件名来命名
        category: {
            add: {
                parent: '父級分類',
                status: '狀態'
            }
        },
        images: {
            edit: {
                size: '圖片大小',
                path: '圖片地址'
            }
        },
        // 地区
        region: {
            name: '請輸入地區',
            level: '請選擇等級',
            code: '請輸入行政代碼',
        }
    },
    // From All End

    // 所有(表单)验证提示
    validate: {
        max_length: '限制長度為',
        empty: '此項不能為空',
        empty_radio: '請選擇相關選項',
    },
    // (From) All Tip End

    // 提示(成功/警告)
    tips: {
        search: '請輸入搜索內容',
        select: '請選擇',
        enter: '請輸入內容',
        success: '操作成功',
        error: '操作失敗',
        del: '刪除後將無法恢復，您確定要刪除嗎？',
        del_folder: '請先轉移或刪除此文件夾下所有文件（完成請忽略）',
        not_selected: '沒有選中項，請您選中相關選項',
        selected: '已選 ',
        total: '共 ',
        item: ' 項',

        // 其他（other）：以文件名来命名
        images: {
            query: '請輸入標題或描述',
            folder: '選擇文件夾',
            upload_text: '將圖片拖到此處，或點擊上傳',
            upload_tip: '圖片只能上傳  ( jpg/png )',
            isLt5K: '大小不能超過 500KB',
            limit: '限制上傳為5張圖片',
            limit_tip: '上傳超出限制，請重新上傳'
        }
    },
    // Tip All (Success/Warning)

    // 页面内容
    page: {

    }
    // Page End
}
  