import {
  localSave,
  localRead
} from '@/utils/auth'

export default {
  state: {
    local: localRead('local')
  },
  getters: {

  },
  mutations: {
    setLocal(state, lang) {
      localSave('local', lang)
      state.local = lang
    }
  }
}
