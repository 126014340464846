import {
  login,
  logout,
  getUserInfo
} from '@/api/user'
import {getOption} from '@/api/os/admin';
import { setToken, getToken, setUserData, getUserData, getSessionData, setSessionData } from '@/utils/auth'

export default {
  state: {
    network: false, //是否断网
    userInfo: getUserData(),
    token: getToken(),
    menus: getSessionData('menus', true)
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      setUserData(userInfo)
    },
    setToken (state, token) {
      state.token = token
      setToken(token)
    },
    // 改变状态
    changeNetwork(state, val){
      state.network = val
    },
    setMenus(state, val) {
      state.menus = val
      setSessionData('menus', JSON.stringify(val), true)
    }
  },
  actions: {
    // 登录
    handleLogin ({ commit }, { username, password }) {
      username = username.trim()
      return new Promise((resolve, reject) => {
        login({ username, password }).then(res => {
          commit('setUserInfo', res.data.data)
          commit('setToken', res.data.data.apiAuth)
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出登录
    handleLogOut ({ state, commit }) {
      return new Promise((resolve, reject) => {
        logout().then((res) => {
          commit('setToken', '')
          commit('setUserInfo', '')
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取用户相关信息
    getUserInfo ({ state, commit }) {
      if (JSON.stringify(state.userInfo) !== '{}') {
        return state.userInfo
      } else {
        return new Promise((resolve, reject) => {
          try {
            getUserInfo().then(res => {
              commit('setUserInfo', res.data.data)
              resolve(res.data.data)
            }).catch(err => {
              reject(err)
            })
          } catch (error) {
            reject(error)
          }
        })
      }
    },
    // 获取菜单
    getMenus ({ state, commit }) {
      if (state.menus !== false) {
        return state.menus
      } else {
        return new Promise((resolve, reject) => {
          try {
            getOption({
              name: 'page-menu',
            }).then(res => {
              commit('setMenus', res.data.data)
              resolve(res.data.data)
            }).catch(err => {
              reject(err)
            })
          } catch (error) {
            reject(error)
          }
        })
      }
    }
  }
}
