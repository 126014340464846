<template>
  <div id="app">
    <no-network v-if="network"></no-network>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noNetwork from '@/view/errorPage/network';



export default {
  name: 'App',
  computed: {
    ...mapState({
     network:state => state.user.network
    })
  },
  components: {
    noNetwork
  }
}
</script>