import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui';
Vue.prototype.$message = Message;

// 解决ElementUI导航中的vue-route在3.0版本以上重复点菜单报错
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


Vue.use(Router)
const createRouter = () => new Router({
  mode: 'history',
  routes
})
const router = createRouter()

// 路由守卫
router.beforeEach((to,from,next)=> {
  // 开始进度条
  NProgress.start()
  if(to.meta.isLogin) { 
    // 页面需要登录才可操作
    if (sessionStorage['apiAuth']) { 
      console.log('1','登陆')
      // 用户已登录
      // 路由权限验证
      next()
    } else {
      console.log('2','未登陆')
      Message.warning('请先进行登录')
      next({
        path:'/login',
        query:{
          // 将跳转的路由path作为参数，登录成功后跳转到该路由
          redirect:to.fullPath
        }
      })
      return NProgress.done()
    }
  } else {
    // 不用登陆
    if (to.path === '/login' && sessionStorage['apiAuth']) {
      console.log('4','已登陆访问登录页')
      return next({ path: '/' })
    }
    console.log('3','不需要登陆')
    return next()
  }
});


router.afterEach(() => {
  // 完成进度条
  NProgress.done()
})

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

export default router;