export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: 'ApiAdmin身边的接口管理专家',
    /**
     * @description 是否使用国际化，默认为false
     *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
     *              用来在菜单中显示文字
     */
    useI18n: true,
    /**
     * @description api请求基础路径
     */
    baseUrl: {
      dev: 'https://j.360s.net',
      pro: 'https://j.360s.net/'
    },
    AccessToken: 'tqeGKsoUHRnJjnyuKTSFmgEUqMaNtBsE',
    /**
     * 
     */
    default: {
      pageLimit: 12,
      pageSizes: [12, 24, 32, 64],
      taxonomyIndex: 'productType',
      url: {
        post: '/post/',
        product: '/productDetail/',
        solution: '/solutionDetail/',
      },
      taxonomy: [
        {
          name: '产品类型',
          en_name: 'Product Type',
          value: 'productType'
        },
        {
          name: '应用行业',
          en_name: 'Product App',
          value: 'productApp'
        },
        {
          name: '工件/基材',
          en_name: 'Workpiece',
          value: 'workpiece'
        },
        {
          name: '子公司',
          en_name: 'Subsidiary',
          value: 'subsidiary'
        },
      ],
      technicalType: [
        {
          name: '研发流程',
          en_name: 'researchFlow',
          value: 'researchFlow'
        },
        {
          name: '研发设备',
          en_name: 'researchEquip',
          value: 'researchEquip'
        },
        {
          name: '研发团队',
          en_name: 'researchItem',
          value: 'researchItem'
        }
      ],
      mediaType: [
        {
          name: '企业动态',
          en_name: 'enterpriseDynamics',
          value: 'enterpriseDynamics'
        },
        {
          name: '行业动态',
          en_name: 'industryDynamics',
          value: 'industryDynamics'
        },
        {
          name: '媒体报道',
          en_name: 'mediaReport',
          value: 'mediaReport'
        },
        {
          name: '企业图片',
          en_name: 'enterprise Image',
          value: 'enterpriseImages'
        },
        {
          name: '活动图片',
          en_name: 'activity Image',
          value: 'activityImages'
        },
        {
          name: '视频资料',
          en_name: 'mediaVideo',
          value: 'mediaVideo'
        }
      ],
      investorType: [
        {
          name: '基本资料',
          en_name: 'basicInformation',
          value: 'info'
        },
        // {
        //   name: '公司报告',
        //   en_name: 'companyReport',
        //   value: 'companyReport'
        // },
        {
          name: '股价走势',
          en_name: 'stockPriceTrend',
          value: 'stockInfo'
        },
        {
          name: '公司公告',
          en_name: 'corporateReport',
          value: 'corporateReport'
        },
        {
          name: '定期报告',
          en_name: 'periodicReport',
          value: 'periodicReport'
        },
        {
          name: '投资者教育',
          en_name: 'InvestorEducation',
          value: 'investorEducation'
        },
        /*{
          name: '公司治理',
          en_name: 'corporateGovernance',
          value: 'corporateGovernance'
        },*/
        {
          name: '投资者保护与联络',
          en_name: 'investorContact',
          value: 'investorContact'
        }
      ],
      productStatus: ['私密', '公开'],
      optionName: {
        home: 'page-home',

        wx: 'wx-page',
      }
    },
    /**
     * @description 需要加载的插件
     */
    plugin: {
  
    }
}