/*
 * 命名规范
 * 全小写 以 ` _ ` 作为分割
 * 英文翻译首字母大写
 */
export default {
    // 全局公共翻译
    home: 'Home',
    login: 'Login',
    search: 'Search',
    tip: 'Tips',
    lang: 'Lang',
    create_time: 'Create Time',
    update_time: 'Update Time',
    action: 'Action',
    empty: 'Empty',
    all: 'All',
    content: 'Content',
    url: 'Url',
    icon: 'Icon',
    image: 'Image',
    type: 'Type',
    category: 'Category',
    name: 'Name',

    site: {
        name: 'Golden Sun',
        stknme: 'Stknme',
        code: 'Code',
        info: 'Basic Information',
        investor_contact: 'InvestorContact'
    },

    // 按钮
    button: {
        cancel: 'Cancel',
        ok: 'OK',
        save: 'Save',
        del: 'Delete',
        add: 'Add',
        edit: 'Edit',
        refresh: 'Refresh',
        select_all: 'Select all',
        // Category
        add_category: 'Add category',
        // Images
        upload_image: 'Upload image',
        new_folder: 'New folder',
        edit_folder: 'Edit folder',
        go_back: 'Go Back',
        page: 'Page'
    },

    // 主导航菜单
    menu: [
        { path: '/home', title: 'Home'},
        { path: '/goldenSun', title: 'Golden Sun'},
        { path: '/solution', title: 'Solution'},
        { path: '/productCenter', title: 'Product center'},
        { path: '/technicalSupport', title: 'Technical support'},
        { path: '/investor', title: 'Investor'},
        { path: '/media', title: 'Media'},
    ],

    footer: [
        { path: '/home', title: 'Golden Sun'},
    ],

    // 表单
    form: {
        // 公共部分
        name:   'Name',
        title:  'Title',
        desc:   'Describe',
        status: 'Status',
        create_time: 'Create time',
        update_time: 'Update time',
        select_taxonomy: 'Select Taxonomy',
        order: 'Order',

        // 其他（other）：以文件名来命名
        category: {
            add: {
                parent: 'Parent',
                status: 'Status'
            }
        },
        images: {
            edit: {
                size: 'Size',
                path: 'Path'
            }
        },
        // 地区
        region: {
            name: 'Please enter the region',
            level: 'Please select a level',
            code: 'Please enter the administrative code',
        }
    },

    // 验证
    validate: {
        max_length: 'The limit length is',
        empty: 'This item cannot be empty',
        empty_radio: 'Please select the relevant option',
    },

    // 提示
    tips: {
        search: 'Please enter the search content',
        select: 'Select',
        enter: 'Please enter the content',
        success: 'success',
        error: 'Failed',
        del: 'Are you sure you want to delete it?',
        del_folder: 'Please transfer or delete all files in this folder first (Please ignore when finished)',
        not_selected: 'Please select the relevant option',
        selected: 'Selected ',
        total: 'Total ',
        item: ' Item',

        // Images
        images: {
            query: 'Please enter a title or description',
            folder: 'Select Folder',
            upload_text: 'Drag the picture here, or click upload',
            upload_tip: 'Pictures can only be uploaded  ( jpg/png )',
            isLt5K: 'The size cannot exceed 500KB',
            limit: 'Limit upload to 5 pictures',
            limit_tip: 'The upload exceeds the limit, please upload again'
        },

        error_post: 'Post Empty'
    },

    // 页面内容
    page: {
        home: {
            more: 'More',
            email: 'Email',
            address: 'Address',
        },

        // console
        console: {
            menu: {
                notice: 'Notice',
                home: 'Home',
                activity: 'Activity Center',
                add_activity: 'Add Activity',
                msg: 'Msg',
                setting: 'Setting',
                account: 'Account',
                manage: 'Manage',
                images: 'Images',
                category: 'Category',
                address: 'Address',
                product_type: 'Product Type',
                product_app: 'Product App',
                product: 'Product',
                product_list: 'Product List',
                solution: 'Solution',
                solution_list: 'Solution List',
                page_settings: 'Page Settings',
                home_settings: 'HomePage Settings',
                menu_settings: 'Menu Settings',
                banner_settings: 'Banners Settings',
                investor_settings: 'Investor Settings',
                technicalSupport_settings: 'TechnicalSupport Settings',
                ad_settings: 'Advertisement Settings',
                product_center: 'Product Center',
                golden_sun: 'Golden Sun',
                technical_support: 'Technical Support',
                technical_support_list: 'Technical Support List',
                media: 'Media',
                media_list: 'Media List',
                investor: 'Investor',
                investor_list: 'Investor List',
                dynamics: 'Dynamics',
                activity_images: 'Activity Images',
                title: 'Title',
                desc: 'Desc',
                list: 'List',
                about_us: 'About us',
                contact_us: 'Contact us',
                copyright: 'copyright',
                wxCode: 'wxCode',
            },
            ad_settings: {
                posts: 'Posts'
            }
        },
        media: {
            video_url: 'Video Url',
            images: 'Images'
        },
        product: {
            name: 'Name',
            model: 'Model',
            desc: 'Desc',
            images: 'Images',
            product_type: 'Product Type',
            product_app: 'Product App',
            workpiece: 'Workpiece',
            subsidiary: 'Subsidiary',
            content: 'Content',
            wxVideo: 'Video ID'
        },
        goldenSun: {
            company_profile: 'Company Profile',
            vision_mission: 'Vision Mission',
            development_history: 'Development History',
            business_culture : 'Business Culture',
            talent_concept: 'Talent Concept',
            social_responsibility: 'Social Responsibility',
            year: 'Year'
        },
        technicalSupport: {
            title: 'Title',
            name: 'Name',
            position: 'Position',
        },
        productCenter: {
            product: 'Product',
            apps: 'Apps',
            asc: 'ASC',
            desc: 'DESC',
            current_taxonomy: 'Current Taxonomy',
            product_nums: 'Product Nums'
        },
        investor: {
            view_pdf: 'View PDF',
            year: 'Year',
            create_time: 'Create Time'
        },
        productType: {
            category: 'Category',
            desc: 'Desc',
            order: 'Order',
            create_user: 'Create User',
            create_time: 'Create Time',
            update_time: 'Update Time'
        }
    }
}
  