import axios from '@/utils/request';

/**
 * 新增产品类型
 */
export const createProductType = (data) => {
  return axios.request({
    url: '/api/ProductType/create',
    method: 'post',
    data
  })
}

/**
 * 修改产品类型
 */
export const updateProductType = (data) => {
  return axios.request({
    url: '/api/ProductType/update',
    method: 'post',
    data
  })
}

/**
 * 获取产品类型
 */
export const getProductType = ({page, size, query}) => {
  return axios.request({
    url: '/api/ProductType/get',
    method: 'get',
    params: {
      page: page,
      limit: size,
      query: query
    }
  })
}

/**
 * 删除产品类型
 */
export const removeProductType = (id) => {
  return axios.request({
    url: '/api/ProductType/remove',
    method: 'get',
    params: {
      id
    }
  })
}


/**
 * 新增行业应用
 */
export const createProductApp = (data) => {
  return axios.request({
    url: '/api/ProductApp/create',
    method: 'post',
    data
  })
}

/**
 * 修改行业应用
 */
export const updateProductApp = (data) => {
  return axios.request({
    url: '/api/ProductApp/update',
    method: 'post',
    data
  })
}

/**
 * 获取行业应用
 */
export const getProductApp = ({page, size, query}) => {
  return axios.request({
    url: '/api/ProductApp/get',
    method: 'get',
    params: {
      page: page,
      limit: size,
      query: query
    }
  })
}

/**
 * 删除行业应用
 */
export const removeProductApp = (id) => {
  return axios.request({
    url: '/api/ProductApp/remove',
    method: 'get',
    params: {
      id
    }
  })
}

/**
 * getOption
 */
export const getOption = (params) => {
  return axios.request({
    url: '/api/Option/get',
    method: 'get',
    params
  })
}

/**
 * createOption
 */
export const createOption = (data) => {
  return axios.request({
    url: '/api/Option/create',
    method: 'post',
    data
  })
}

/**
 * createOption
 */
export const updateOption = (data) => {
  return axios.request({
    url: '/api/Option/update',
    method: 'post',
    data
  })
}


/**
 * 新增 解决方案
 */
export const createSolution = (data) => {
  return axios.request({
    url: '/api/Solution/create',
    method: 'post',
    data
  })
}

/**
 * 修改 解决方案
 */
export const updateSolution = (data) => {
  return axios.request({
    url: '/api/Solution/update',
    method: 'post',
    data
  })
}

/**
 * 获取 解决方案
 */
export const getSolution = (params) => {
  return axios.request({
    url: '/api/Solution/get',
    method: 'get',
    params
  })
}

/**
 * 删除 解决方案
 */
export const removeSolution = (id) => {
  return axios.request({
    url: '/api/Solution/remove',
    method: 'get',
    params: {
      id
    }
  })
}


/**
 * 保存分类
 */
export const saveTaxonomy = (data) => {
  return axios.request({
    url: '/api/Taxonomy/save',
    method: 'post',
    data
  })
}

/**
 * 获取分类
 */
export const getTaxonomy = (params) => {
  return axios.request({
    url: '/api/Taxonomy/get',
    method: 'get',
    params
  })
}

/**
 * 获取分类
 */
export const getTaxonomyAll = (params) => {
  return axios.request({
    url: '/api/Taxonomy/getAll',
    method: 'get',
    params
  })
}

/**
 * 获取分类所有
 */
export const getListTaxonomy = (params) => {
  return axios.request({
    url: '/api/Taxonomy/getList',
    method: 'get',
    params
  })
}

/**
 * 删除分类
 */
export const removeTaxonomy = (id) => {
  return axios.request({
    url: '/api/Taxonomy/remove',
    method: 'get',
    params: {
      id: id
    }
  })
}

/**
 * 新增产品
 */
export const createProduct = (data) => {
  return axios.request({
    url: '/api/Product/create',
    method: 'post',
    data
  })
}

/**
 * 更新产品
 */
export const updateProduct = (data) => {
  return axios.request({
    url: '/api/Product/update',
    method: 'post',
    data
  })
}

/**
 * 产品列表
 */
export const getProduct = (params) => {
  return axios.request({
    url: '/api/Product/get',
    method: 'get',
    params
  })
}

/**
 * 删除产品
 */
export const removeProduct = (id) => {
  return axios.request({
    url: '/api/Product/remove',
    method: 'get',
    params: {
      id
    }
  })
}

/**
 * 保存文章
 */
export const savePosts = (data) => {
  return axios.request({
    url: '/api/Posts/save',
    method: 'post',
    data
  })
}

/**
 * 查询文章
 */
export const getPosts = (params) => {
  return axios.request({
    url: '/api/Posts/get',
    method: 'get',
    params
  })
}

/**
 * 删除产品
 */
export const removePosts = (id) => {
  return axios.request({
    url: '/api/Posts/remove',
    method: 'get',
    params: {
      id
    }
  })
}

/**
 * 查询股票信息
 */
export const getStockInfo = (params) => {
  return axios.request({
    url: '/api/Stock/get',
    method: 'get',
    params
  })
}

/**
 * 获取当前用户信息
 * @returns {never}
 */
export const getUserInfo = () => {
  return axios.request({
    url: 'admin/Login/getUserInfo',
    method: 'get'
  })
}


export const own = (data) => {
  return axios.request({
    url: 'admin/User/own',
    method: 'post',
    data
  })
}
