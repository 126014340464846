/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */

import axios from 'axios'
import config from '@/config'
import router from '@/router'
import store from '@/store';
import { setToken, getToken, setUserData } from '@/utils/auth'
import { Message, Notification } from 'element-ui';

export const baseUrl = (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro)

/** 
 * 提示函数 
 * 显示一秒后关闭
 */

export const tip = (status, msg)=> {
  Notification.error({
    title: status,
    message: msg,
    position: 'bottom-right',
  });
}

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
export const errorHandle = (status, other) => {
  switch (status) {
      case 403:
        tip(status, '登录过期，请重新登录')
        // 清除token
        // localStorage.removeItem('token');
        // store.commit('loginSuccess', null);
        // setTimeout(() => {
        //     toLogin();
        // }, 1000);
        break;
      case 404:
        tip(status, '网络请求失败')
        break;
      default:
        tip(status, other)
  }
}



class HttpRequest {
    constructor (baseUrl) {
      this.baseUrl = baseUrl
    }
  
    interceptors (instance) {
      // 请求拦截
      instance.interceptors.request.use(
        config => {
          return config
        }, 
        error => {
          return Promise.reject(error)
        }
      )
      // 响应拦截
      instance.interceptors.response.use(
        // 请求成功
        res => {
          const { data, status } = res
          if (data.code < 0) {
            if (data.code === -14) {
              if(getToken()) {
                Message.error('你已被登出，重新登录')
              }
              setToken('')
              setUserData('')
              // 路由登录页定向
              router.replace({
                path: '/login',
                query: {
                  redirect: router.currentRoute.fullPath
                }
              });
            } else {
              Message.error(data.msg)
            }
            throw new Error(data.msg)
          } else {
            return { data, status }
          }
        }, 

        // 请求失败
        error => {
          // return Promise.reject(error)
          const { response } = error;
          if (response) {
            // 请求已发出，但是不在200的范围
            errorHandle(response.status, response.data.msg);
            return Promise.reject(response);
          } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            store.commit('changeNetwork', true);
          }
        }
      )
    }
  
    request (options) {
      const instance = axios.create()
      const apiAuth = getToken()
      if (apiAuth === false) {
        options = Object.assign({
          baseURL: this.baseUrl,
          headers: {
            'access-token': config.AccessToken,
          }
        }, options)
      } else {
        options = Object.assign({
          baseURL: this.baseUrl,
          headers: {
            'access-token': config.AccessToken,
            'api-auth': apiAuth
          }
        }, options)
      }
      this.interceptors(instance)
      return instance(options)
    }
}
  
const api_axios = new HttpRequest(baseUrl)
export default api_axios