<template>
    <div class="network">
        <el-row>
            <el-col :span="24">
                <img src="@/assets/images/common/404.svg">
                <el-link type="warning">Tips: {{tips}}</el-link>
            </el-col>
            <el-col :span="24" class="mt20">
                <el-button type="warning" :loading="loading" @click="renovate" size="mini" round>{{text}}</el-button>
                <el-button type="warning" @click="goHome" size="mini" round>返回首页</el-button>
            </el-col>
      </el-row>
    </div>
</template>

<script>
export default {
    name: 'no-network',
    data() {
        return {
            loading: false,
            tips: '网络连接已断开',
            text: '尝试刷新'
        }
    },
    methods: {
        renovate() {
            this.loading = true
            this.text = ''
            setTimeout(function() {
                location.reload()
            }, 2000);
        },
        goHome() {
            this.$router.push('/')
            setTimeout(function() {
                location.reload()
            }, 1000);
        }
    }
}
</script>

<style lang="less" scoped>
.network{
    margin: auto;
    max-width: 1000px;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center; 
    justify-content: center;
}
.network img{
    width: 100%;
}
</style>