export const otherRouter = [
    { 
      path: '/login', 
      name: 'login', 
      meta: { 
        title: '登录', 
        desc: '用户登录页'
      }, 
      component: () => import('@/view/login') 
    },
    { 
      path: '/', 
      name: 'index',
      redirect: '/home',
      component: () => import('@/view/app/Main'),
      children: [
        { 
          path: '/home', 
          name: 'home', 
          meta: { 
            title: '首页', 
            desc: '首页' 
          }, 
          component: () => import('@/view/app/Home') 
        },
        {
          path: '/cost',
          name: 'cost',
          meta: {
            title: '缴纳费用',
            desc: '缴纳费用'
          },
          component: () => import('@/view/cost/index')
        },
        {
          path: '/goldenSun/:id?',
          name: 'goldenSun',
          meta: {
            title: '走进金太阳',
            desc: '走进金太阳'
          },
          component: () => import('@/view/os/GoldenSun')
        },
        {
          path: 'solution',
          name: 'solution',
          meta: {
            title: '解决方案',
          },
          component: () => import('@/view/os/Solution')
        },
        {
          path: 'solutionDetail/:id',
          name: 'solutionDetail',
          meta: {
            title: '解决方案详情',
          },
          component: () => import('@/view/os/SolutionDetail')
        },
        {
          path: 'productCenter/:type?/:id?',
          name: 'productCenter',
          meta: {
            title: '产品中心',
          },
          component: () => import('@/view/os/ProductCenter')
        },
        {
          path: 'productDetail/:id',
          name: 'productDetail',
          meta: {
            title: '产品详情',
          },
          component: () => import('@/view/os/ProductDetail')
        },
        {
          path: 'technicalSupport',
          name: 'technicalSupport',
          meta: {
            title: '产品中心',
          },
          component: () => import('@/view/os/TechnicalSupport')
        },
        {
          path: 'post/:id/:type?',
          name: 'post',
          meta: {
            title: '文章',
            desc: '文章'
          },
          component: () => import('@/view/os/Post')
        },
        {
          path: 'media/:type?',
          name: 'media',
          meta: {
            title: '产品中心',
          },
          component: () => import('@/view/os/Media')
        },
        {
          path: 'investor/:type?',
          name: 'investor',
          meta: {
            title: '投资者',
          },
          component: () => import('@/view/os/Investor')
        },



        { 
          path: '/job', 
          name: 'job', 
          meta: { 
            title: '找工作', 
            desc: '找工作页' 
          }, 
          component: () => import('@/view/job/Seek') 
        },
        {
          path: '/find',
          name: 'find', 
          meta: { 
            title: '订单', 
            desc: '寻找页' 
          }, 
          component: () => import('@/view/order/Find')
        },
        { 
          path: '/shop', 
          name: 'shop', 
          meta: { 
            title: '商城', 
            desc: '商品中心' 
          }, 
          component: () => import('@/view/shop') 
        },
        { 
          path: '/sign', 
          name: 'sign', 
          meta: { 
            title: '登入', 
            desc: '登入' 
          }, 
          component: () => import('@/view/user/Sign') 
        },
        { 
          path: '/pricing', 
          name: 'pricing', 
          meta: { 
            title: '价格', 
            desc: '价格' 
          }, 
          component: () => import('@/view/pricing') 
        },
        { 
          path: '/search', 
          name: 'search', 
          meta: { 
            title: '价格', 
            desc: '价格' 
          }, 
          component: () => import('@/view/search') 
        },
      ]
    },
    { 
      path: '/console', 
      name: 'console', 
      component: () => import('@/view/console'),
      redirect: 'console/homeSettings',
      children: [
        {
          path: 'menuSettings',
          name: 'console_menuSettings',
          meta: {
            title: '菜单设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/MenuSettings')
        },
        {
          path: 'homeSettings',
          name: 'console_homeSettings',
          meta: {
            title: '首页设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/HomeSettings')
        },
        {
          path: 'bannerSettings',
          name: 'console_bannerSettings',
          meta: {
            title: 'Banner设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/BannerSettings')
        },
        {
          path: 'goldenSunSettings',
          name: 'console_goldenSunSettings',
          meta: {
            title: '走进金太阳设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/GoldenSunSettings')
        },
        {
          path: 'investorSettings',
          name: 'console_investorSettings',
          meta: {
            title: '投资者设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/InvestorSettings')
        },
        {
          path: 'technicalSupportSettings',
          name: 'console_technicalSupportSettings',
          meta: {
            title: '技术支持设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/TechnicalSupportSettings')
        },
        {
          path: 'adSettings',
          name: 'console_adSettings',
          meta: {
            title: '广告设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/AdSettings')
        },
        {
          path: 'product/:id?',
          name: 'console_product',
          meta: {
            title: '产品',
            isLogin: true,
          },
          component: () => import('@/view/os/console/Product')
        },
        {
          path: 'productList',
          name: 'console_productList',
          meta: {
            title: '产品列表',
            isLogin: true,
          },
          component: () => import('@/view/os/console/ProductList')
        },
        {
          path: 'productType',
          name: 'console_productType',
          meta: {
            title: '产品类型',
            isLogin: true,
          },
          component: () => import('@/view/os/console/ProductType')
        },
        {
          path: 'technicalSupport/:id?',
          name: 'console_technicalSupport',
          meta: {
            title: '技术支持',
            isLogin: true,
          },
          component: () => import('@/view/os/console/TechnicalSupport')
        },
        {
          path: 'technicalSupportList',
          name: 'console_technicalSupportList',
          meta: {
            title: '技术支持',
            isLogin: true,
          },
          component: () => import('@/view/os/console/TechnicalSupportList')
        },
        {
          path: 'productApp',
          name: 'console_productApp',
          meta: {
            title: '应用行业',
            isLogin: true,
          },
          component: () => import('@/view/os/console/ProductApp')
        },
        {
          path: 'solution',
          name: 'console_solution',
          meta: {
            title: '解决方案',
            isLogin: true,
          },
          component: () => import('@/view/os/console/Solution')
        },
        {
          path: 'solutionList',
          name: 'console_solutionList',
          meta: {
            title: '解决方案列表',
            isLogin: true,
          },
          component: () => import('@/view/os/console/SolutionList')
        },
        {
          path: 'media',
          name: 'console_media',
          meta: {
            title: '解决方案',
            isLogin: true,
          },
          component: () => import('@/view/os/console/Media')
        },
        {
          path: 'mediaList',
          name: 'console_mediaList',
          meta: {
            title: '解决方案',
            isLogin: true,
          },
          component: () => import('@/view/os/console/MediaList')
        },
        {
          path: 'investor',
          name: 'console_investor',
          meta: {
            title: '解决方案',
            isLogin: true,
          },
          component: () => import('@/view/os/console/Investor')
        },
        {
          path: 'investorList',
          name: 'console_investorList',
          meta: {
            title: '解决方案',
            isLogin: true,
          },
          component: () => import('@/view/os/console/InvestorList')
        },
        {
          path: 'wx',
          name: 'console_wx',
          meta: {
            title: '解决方案',
            isLogin: true,
          },
          component: () => import('@/view/os/console/wx')
        },

        {
          path: 'account',
          name: 'console_account',
          meta: {
            title: '用户设置',
            isLogin: true,
          },
          component: () => import('@/view/os/console/account')
        },








        { 
          path: 'index', 
          name: 'console_index', 
          meta: {
            title: '控制中心',
            isLogin: true,
          },
          component: () => import('@/view/console/home') 
        },
        {
          path: 'announcement',
          name: 'console_announcement',
          mete: {
            title: '公告',
            isLogin: true
          },
          component: () => import('@/view/console/announcement')
        }
        ,
        { 
          path: 'activity', 
          name: 'console_activity', 
          meta: {
            title: '活动中心',
            isLogin: true,
          },
          component: () => import('@/view/console/activity') 
        },
        { 
          path: 'addActivity',
          name: 'console_activity_add', 
          meta: {
            title: '添加活动',
            isLogin: true,
          },
          component: () => import('@/view/console/activity/add')
        },
        { 
          path: 'message', 
          name: 'console_message', 
          meta: {
            title: '消息中心',
            isLogin: true,
          },
          component: () => import('@/view/console/message') 
        },
        // 账户设置
        { 
          path: 'setting', 
          name: 'console_setting', 
          component: () => import('@/view/console/setting'),
          children: [
            { 
              path: 'account', 
              name: 'setting_account',
              meta: {
                title: '账户设置',
                isLogin: true,
              },
              component: () => import('@/view/console/setting/account') 
            },
            { 
              path: 'filter', 
              name: 'setting_filter', 
              meta: {
                title: '屏蔽管理',
                isLogin: true,
              },
              component: () => import('@/view/console/setting/filter') 
            },
            { 
              path: 'privacy', 
              name: 'setting_privacy', 
              meta: {
                title: '隐私',
                isLogin: true,
              },
              component: () => import('@/view/console/setting/privacy') 
            },
            { 
              path: 'preference', 
              name: 'setting_preference', 
              meta: {
                title: '偏好设置',
                isLogin: true,
              },
              component: () => import('@/view/console/setting/preference') 
            },
          ]
        },
        // 管理
        {
          path: 'admin', 
          name: 'console_admin', 
          component: () => import('@/view/console/admin'),
          children: [
            { 
              path: 'choose',
              name: 'admin_choose',
              meta: {
                title: '选择设置',
                isLogin: true,
              },
              component: () => import('@/view/console/admin/choose'),
              children: [
                { 
                  path: 'region',
                  name: 'admin_choose_region',
                  meta: {
                    title: '地址',
                    isLogin: true,
                  },
                  component: () => import('@/view/console/admin/choose/region'),
                },
                { 
                  path: 'address',
                  name: 'admin_choose_address',
                  meta: {
                    title: '地址',
                    isLogin: true,
                  },
                  component: () => import('@/view/console/admin/choose/address'),
                },
                { 
                  path: 'category',
                  name: 'admin_choose_category',
                  meta: {
                    title: '类别',
                    isLogin: true,
                  },
                  component: () => import('@/view/console/admin/choose/category'),
                },
                { 
                  path: 'images',
                  name: 'admin_choose_images',
                  meta: {
                    title: '图片管理',
                    isLogin: true,
                  },
                  component: () => import('@/view/console/admin/choose/images'),
                }
              ]
            },
          ]
        }
      ]
    },
    {
      path: '*', 
      name: '404',
      meta: {
        title: '页面走丢了'
      },
      component: ()=> import('@/view/errorPage/404')
    }
  ]

export const routes = [
  ...otherRouter
]

export default routes
